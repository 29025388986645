<template>
  <div class="form">
    <template v-for="item in vaccinesList">
      <div class="weui-cells">
        <apicker
          :options="types"
          text="疫苗名称"
          :model.sync="item.typeId"
          placeholder="请选择"
          :required="true"
        ></apicker>
        <ainput
          text="输入疫苗种类"
          placeholder="请输入"
          :model.sync="other"
          v-if="item.typeId == -1"
          :required="true"
        ></ainput>
        <ainput
          text="疫苗生产编号"
          placeholder="请输入"
          :model.sync="item.batchNo"
        ></ainput>
      </div>
      <div class="weui-cells">
        <apicker
          :options="count"
          text="接种第几针"
          :model.sync="item.count"
          placeholder="请选择"
        ></apicker>
        <adate
          text="接种疫苗日期"
          :model.sync="item.time"
          placeholder="请选择"
        ></adate>
        <ainput
          text="接种地址"
          placeholder="请输入"
          :model.sync="item.address"
        ></ainput>
      </div>
    </template>
    <div class="btn-area">
      <button class="weui-btn weui-btn_primary" @click="submit">确认</button>
    </div>
    <!-- 历史记录 -->
    <section class="historyBox" v-if="!isEdit">
      <p class="trigger">接种疫苗记录</p>
      <el-timeline class="timeline">
        <el-timeline-item
          v-for="(item, index) in vList"
          :key="index"
          :timestamp="item.time.substr(0, 10) + `   第${item.count || 1}针`"
          style="position: relative"
        >
          <p>接种类型：{{ item.vaccinesType ? item.vaccinesType.name : "" }}</p>
          <p>接种地址：{{ item.address }}</p>
        </el-timeline-item>
      </el-timeline>
      <p class="viewAllBtn" @click="goHistory" v-if="!isEdit">点击查看全部</p>
    </section>
  </div>
</template>

<script>
import ainput from "@/components/form/input";
import apicker from "@/components/form/picker";
import adate from "@/components/form/datepicker";
import aradio from "@/components/form/radio";
import acell from "@/components/form/cell";
import aswitch from "@/components/form/switch";
import webview from "@/mixins/webview";
import {
  VaccinesType,
  EpidemicSave,
  nucleicList,
  vaccinesList,
  vaccineTree,
  addVaccineType,
  EditVac,
} from "@/api/api";
import time from "@/mixins/time";
export default {
  data() {
    return {
      name: "",
      check: [
        {
          label: "是",
          value: 1,
        },
        {
          label: "否",
          value: 0,
        },
      ],
      types: [],
      vaccinesList: [
        {
          time: this.nowTime(),
          typeId: "",
          address: "",
          count: 1,
          typeAId: "",
          batchNo: "",
        },
      ], //接种疫苗记录提交
      vList: [], //疫苗历史记录
      form: {},
      vflag: false,
      count: [
        {
          label: "第一针",
          value: 1,
        },
        {
          label: "第二针",
          value: 2,
        },
        {
          label: "第三针",
          value: 3,
        },
        {
          label: "第四针",
          value: 4,
        },
      ],
      vaccines: [],
      treeData: [],
      other: "",
      isEdit: false,
    };
  },
  components: {
    ainput,
    apicker,
    adate,
    aradio,
    acell,
    aswitch,
  },
  created() {
    this.isEdit = Boolean(this.$route.query.isEdit);
    document.title = this.isEdit ? "编辑接种记录" : "接种疫苗";
    this.$route.query.data &&
      (this.vaccinesList = [JSON.parse(this.$route.query.data)]);
    this.id = this.$route.query.id;
  },
  mounted() {
    this.isWebView();
    this.getVaccinesType();
    this.getNucList();
    this.getVacList();
  },
  mixins: [time, webview],
  methods: {
    goHistory() {
      let mobile = this.$store.state.userInfo.mobile;
      this.$router.push("/detailList/" + mobile + "?type=vaccine");
    },
    async submit() {
      let form = {
        vaccinesList: this.vaccinesList,
        mobile: this.$store.state.userInfo.mobile,
        nucleicAcidList: [],
      };
      if (!form.vaccinesList[0].typeId) {
        this.$weui.toast("疫苗名称为必填", {
          duration: 1500,
          className: "error",
        });
        return;
      }
      if (!this.id) {
        EpidemicSave(form).then((res) => {
          this.$weui.toast("提交成功", {
            duration: 1500,
            className: "custom-classname",
          });
          if (window.__wxjs_environment === "miniprogram") {
            setTimeout(() => {
              wx.miniProgram.navigateBack();
            }, 2000);
          } else {
            this.$router.go(-1);
          }
        });
      } else {
        EditVac(this.id, form.vaccinesList[0]).then((res) => {
          this.$weui.toast("修改成功", {
            duration: 1500,
            className: "custom-classname",
          });
          if (window.__wxjs_environment === "miniprogram") {
            setTimeout(() => {
              wx.miniProgram.navigateBack();
            }, 2000);
          } else {
            this.$router.go(-1);
          }
        });
      }
    },
    // 获取疫苗类型
    async getVaccinesType() {
      let res = await vaccineTree();
      this.treeData = res.data.data;
      let arr = res.data.data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      this.types = arr;
    },
    // 获取核酸记录
    async getNucList() {
      let res = await nucleicList({
        mobile: this.$store.state.userInfo.mobile,
      });
      this.nList = res.data.data;
    },
    // 获取疫苗记录
    async getVacList() {
      this.vList = [];
      let res = await vaccinesList({
        mobile: this.$store.state.userInfo.mobile,
      });
      this.vList = res.data.data.slice(0, 3);
    },

    // 记录收起展开
    trigger(type) {
      if (type === "n") {
        this.nflag = !this.nflag;
      } else {
        this.vflag = !this.vflag;
      }
    },
  },
};
</script>

<style scoped>
.addBtn {
  color: #2f7dcd;
}
.iconground i {
  margin-left: 0.2rem;
}
.historyBox {
  padding: 0.1rem 0.2rem 0rem;
}
.trigger {
  margin: 0.1rem 0;
  color: #2f7dcd;
  font-size: 0.14rem;
  /* text-align: right; */
}
.timeline {
  overflow: hidden;
}
.expend {
  height: auto;
}
.btn-area {
  padding-bottom: 0.1rem;
}
</style>